var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-order",
      attrs: {
        width: _vm.isMobileScreen ? "90%" : "80%",
        title: "Link to Term Contract",
        visible: _vm.dialogLinkTermContract,
      },
      on: {
        close: _vm.handleLinkTermContractDialogClose,
        open: _vm.handleLinkTermContractDialogOpen,
      },
    },
    [
      _c(
        "div",
        [
          !_vm.isTermContractTableLoading
            ? _c("table-term-contract", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isTermContractTableLoading,
                    expression: "isTermContractTableLoading",
                  },
                ],
                attrs: {
                  isLinkedSalesTermContract: _vm.isLinkedSalesTermContract,
                  linkedSalesTermContract: _vm.linkedSalesTermContract,
                  linkedPurchaseTermContract: _vm.linkedPurchaseTermContract,
                  hasRadio: "",
                  isTermContractTableLoading: _vm.isTermContractTableLoading,
                  orderTableData: _vm.linkOrderTableData,
                  termContractPagination: _vm.termContractPagination,
                },
                on: {
                  handleTermContractRadioSelectChange:
                    _vm.handleTermContractRadioSelectChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "el-dialog__footer" },
        [
          _c(
            "el-button",
            {
              attrs: { outline: "", round: "" },
              on: { click: _vm.handleLinkTermContractDialogClose },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                round: "",
                type: "primary",
                disabled: !_vm.linkedOrderSelected,
              },
              on: { click: _vm.handleLinkTermContractConfirm },
            },
            [_vm._v(" Confirm ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }